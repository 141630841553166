import './../css/new_hero.css'
import React from "react"
import { Link } from "gatsby"
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import UpdatedAt from './../elements/updatedat.js'
import ReactVivus from 'react-vivus';
import Parallax from 'parallax-js'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"

class Hero extends React.Component {
    constructor(){
      super();
      this.state = {
          data: {"location":"Seattle, Washington"},
          media: '',
          loaded_1:false,
          loaded_2:false
      }
  }

  componentDidMount () {
    fetch("https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/location.json?alt=media")
        .then( (response) => {
            return response.json() })
                .then( (json) => {
                    this.setState({data: json, loaded_1:true})
                })


  }
  render () {
    if(this.state.loaded_1===true){
      return (
        <div className= "heroContainer">
        <div id="parallaxscene">
        <Link to="/journeys">
        <div data-depth="0.2" id="rectangle1"/>
        <div data-depth="0.2" id="rectangle2"/>
        <div id="heroTextContainer" style = {{textAlign:`center`, width:`100%`, zIndex:`20`,fontSize:`large`}}><h1> Hi there! I'm Brett.</h1><a style={{color:`#7d4aa3`}}>Currently adventuring around <b>{this.state.data.location}</b></a><UpdatedAt/></div>
        <ReactVivus id="map" style ={{opacity:`0.6`}} option={{file: "https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/square_hero.svg?alt=media", type: 'sync',onReady: console.log,duration: 200,start: 'autostart'}}/>
        </Link>
        </div>
        </div>)
    }
    else{
      return(
        <div css={{height:`200vh`, width:`100vw`, zIndex:10, backgroundColor:`white`}}/>
      )
    }

}
}

export default Hero

import React, { PureComponent } from 'react';

export default class UpdatedAt extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
        loaded: false,
        data:[]
    }
}

componentDidMount() {
    fetch("https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/last_updated.json?alt=media")
        .then( (response) => {
            return response.json() })
                .then( (json) => {
                    this.setState({data: json, loaded:true})
                })

}

  render() {
    if (this.state.loaded){
      return (
        <div css={{marginTop:`1.5rem`}}>
        <p css = {{height: `1rem`, fontSize:`small`, color:`gray`}}> Last updated {Math.floor((Date.now() - (parseFloat(this.state.data.last_updated)*1000))/60000)} minutes ago. </p>
    </div>);
    }
    else {
      return (
        <div>
        <p css = {{minHeight:`1rem`}}> </p>
    </div>);
    }

    }
  }

import React from "react"
import Img from 'gatsby-image'
import './../css/blogfeature.css'
import { StaticQuery, graphql, Link } from "gatsby"

class BlogFeature1 extends React.Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query FeatureBlog {
            allMarkdownRemark(sort: { fields: [frontmatter___completion_date], order: DESC }, limit: 1, filter: {frontmatter: {content: {eq: "blog"}}}){
              edges {
                node {
                  excerpt(pruneLength:250)
                  frontmatter {
                    title
                    excerpt
                    path
                    completion_date(formatString: "MMMM D, YYYY")
                    location
                    main_tag
                    img_feature {
                      childImageSharp {
                        fluid(maxWidth:1500) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      } }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          data.allMarkdownRemark.edges.map(({ node }) => (
            <Link to = {node.frontmatter.path}>
            <div className="mainfeature" css = {{width:`100%`,marginTop:`2rem`, display:`grid`, gridTemplateColumns: `2.09fr 1fr`, gridGap: `2rem`}}>
            <div>
            <Img css = {{maxHeight:`22rem`}}fluid = {node.frontmatter.img_feature.childImageSharp.fluid}/>
            </div>
            <div className = "info" css = {{float:`right`, minWidth:`0`,textOverflow:`ellipsis`,width:`auto`, overflowY:`hidden`}}>
            <p css = {{color: `#7d4aa3`, marginBottom:`0.5rem`}}> {node.frontmatter.main_tag}</p>
            <h3 css = {{fontSize:`x-large`, marginBottom:`1rem`}}>{node.frontmatter.title}</h3>
            <p css = {{fontSize: `x-small`, textTransform:`uppercase`, marginBottom:`0.5rem`}}>{node.frontmatter.completion_date}</p>
            <p> {node.frontmatter.excerpt} <br/><a css = {{color:`#B0B0B0`, fontSize:`small`}} href = {node.frontmatter.path}>READ MORE</a></p>

            </div>
            </div>
            </Link>
                ))
        )}
      />

    );
  }
}

export default BlogFeature1

import React from "react"
import Button from './../elements/button.js'
import Fade from 'react-reveal/Fade'
import SectionHeader from './../elements/sectionheader.js'
import BlogFeature1 from './../elements/blogfeature1.js'
import BlogFeature2 from './../elements/blogfeature2.js'
import './../css/blog.css'

export default () => (
  <div css={{background:`white!important`, paddingBottom:`4rem`}}>
  <div css={{textAlign:`left`,'@media (max-width: 750px)':{marginLeft:`5%`, marginRight:`5%`}}}>
  <Fade>
  <SectionHeader text = "Scratchpad"/>
  </Fade>
  <BlogFeature2/>
  <br/>
  <br/>
  <Button link = "/blog" cta ='read more posts' css={{verticalAlign:`middle`}}></Button>
</div>
</div>
)

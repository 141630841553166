import React from "react"
import { StaticQuery, graphql } from "gatsby"
import './../css/navbar.css'
import Preview from './projectpreview.js'

class ProjectCTA extends React.Component {
  render () {
    return (
      <StaticQuery
        query={graphql`{
          CTA:
            allMarkdownRemark(limit: 1, filter: {frontmatter: {content: {eq: "cta"}}}){
              edges {
                node {
                  frontmatter {
                    title
                    path
                    completion_date(formatString: "YYYY")
                    location
                    img_background {
                      childImageSharp {
                        fluid(maxWidth:500) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      } }
                    img_text {   childImageSharp {
                        fluid(maxWidth:500) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      } }
                  }
                }
              }
            }
          }

        `}
        render={data => (
          data.CTA.edges.map(({ node }) => (
            <Preview background_img = {node.frontmatter.img_background.childImageSharp.fluid} text_img = {node.frontmatter.img_text.childImageSharp.fluid} key = {node.frontmatter.title} path= {node.frontmatter.path}></Preview>
          )
        )
      )
      }
      />
    );
  }
}

export default ProjectCTA

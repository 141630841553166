import React from "react"
import MapBackground from './../elements/mapbackground'
import NavBar from './../elements/navbar.js'
import './../css/layout.css'

export default ({ children }) => (
  <div css>
  <div css = {{maxWidth:`700px`, margin:'auto', overflow:`hidden`, paddingLeft:`10px`, paddingRight:`10px`}}>
  <NavBar/>
  <div css = {{margin:'0', padding:'0'}}>

  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

  <div style = {{margin:`0 0% 0 0%`, minWidth:`300px`}}>

  <div>
    {children}
  </div>
  </div>
  </div>
  </div>
  </div>

)

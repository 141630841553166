import React, { PureComponent } from 'react';
import Fade from 'react-reveal/Fade'
import Cookies from 'js-cookie';

import './../css/emailsignup.css'
var validator = require("./../js/validate.js");

export default class Counter extends PureComponent {

  state = {
    email: "",
    message: "",
    already_signed_up: Cookies.get('email'),
    success: false
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    if (validator.validate(this.state.email)) {
      console.log('inside loop')
      this.setState({message:""})
      fetch('/.netlify/functions/signup', {
        method: "POST",
        body: JSON.stringify({
          email: this.state.email,
        })
      })
      Cookies.set('email', 'true');
      this.setState({success:true})
    }
    else {
      this.setState({message: "Oops! Doesn't look like that is a valid email. Try again?"})
    };

  }

  render() {
    if (typeof this.state.already_signed_up !== 'undefined'){
      return(null)
    }
    else {
    return (
      <div css = {{minHeight:`20rem`, borderRadius:`20px`,backgroundColor:`#7d4aa3`, marginBottom:`4rem`,'@media (max-width: 750px)':{marginLeft:`5%`, marginRight:`5%`}}}>
      <div css={{textAlign:`left`, margin:`0 10% 0 10%`,display:`inline-grid`}}>
      <h1 css = {{color:`#FFFFFF`, paddingTop:`4rem`}}> Are you bretty for this? </h1>
      <div className={this.state.success ? 'emailFadeOut' : 'emailFadeIn'}  css = {{display:`inline-block`}}>
      <p css = {{color:`#FFFFFF`, fontSize:`larger`}}> Stay up to date with what I am working on.</p>
      <form onSubmit={this.handleSubmit} css = {{display:`table`, marginBottom:`0`}}>
      <div css={{display:`table-cell`, width:`100%`}}>
          <input
            type="text"
            name="email"
            placeholder = "you@example.com"
            css = {{ backgroundColor:`#7d4aa3`, width:`100%`, borderRadius:`0!important`,border:`4px solid #FFFFFF`, color:`#FFFFFF`, padding:`0`, paddingLeft:`.3rem!important`}}
            value={this.state.email}
            onChange={this.handleInputChange}
          />
          </div>
          <div css={{display:`table-cell`, width:`5rem`}}>
        <button type="submit" css = {{backgroundColor:`#FFFFFF`, color: `#7d4aa3`, padding:`0`,border:`4px solid #FFFFFF`, paddingRight:`.3rem!important`, width:`5rem`}}>SIGN UP</button>
        </div>
      </form>
      <p css = {{position:`relative`, width:`100%`,top:`.8rem`,fontSize:`small`,color:`#FFFFFF`}}> {this.state.message}</p>

      </div>
      <div className={this.state.success ? 'emailFadeIn' : 'emailFadeOut'}>
      <p css = {{color:`#FFFFFF`}}> Thanks so much for signing up; I'm excited to keep ya updated! </p>
      </div>

      </div>
      </div>);}
  }

}

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Preview from './../elements/projectpreview.js'
import ProjectCTA from './../elements/projectcta.js'
import Fade from 'react-reveal/Fade'
import SectionHeader from './../elements/sectionheader.js'

export default () => (
  <div css = {{ borderRadius:`20px`, '@media (max-width: 750px)':{marginLeft:`5%`, marginRight:`5%`}}}>
  <div css={{paddingBottom:`4rem`}}>
  <SectionHeader text = "Some of my work"/>
  <div css={{display:`grid`, gridTemplateColumns: `repeat(2, 1fr)`, gridGap: `2rem`,'@media (max-width: 400px)': {margin:`10%`,gridTemplateColumns: `repeat(1, 1fr)`, gridGap: `2rem`}}}>
  <StaticQuery
    query={graphql`{
      Top7Projects:
        allMarkdownRemark(sort: { fields: [frontmatter___completion_date], order: DESC }, limit: 5, filter: {frontmatter: {content: {in: ["project", "ghostprojectlink"]}}}){
          edges {
            node {
              excerpt(pruneLength:250)
              frontmatter {
                title
                content
                path
                completion_date(formatString: "YYYY")
                location
                img_background {
                  childImageSharp {
                    fluid(maxWidth:500) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  } }
                img_text {   childImageSharp {
                    fluid(maxWidth:500) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  } }
              }
            }
          }
        }
      }
    `}
    render={data => (
      data.Top7Projects.edges.map(({ node }) => (
        <Preview content = {node.frontmatter.content} background_img = {node.frontmatter.img_background.childImageSharp.fluid} text_img = {node.frontmatter.img_text.childImageSharp.fluid} key = {node.frontmatter.title} path= {node.frontmatter.path}></Preview>
      )
    )
  )
  }
  />
  <ProjectCTA css = {{textAlign:`center`}}/>
  </div>

</div>
</div>
)

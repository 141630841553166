import React from "react"
import Img from 'gatsby-image'
import './../css/projectpreview.css'
import { Link } from "gatsby"


class ProjectPreview extends React.Component  {
  constructor(props){
    super(props);
}

render(){
  if(this.props.content!=='ghostprojectlink'){
    return(
      <Link to= {this.props.path}>
      <div className = 'preview'>
      <div className = 'project'>
      <Img className = 'inner_project' fluid = {this.props.background_img}> </Img>
      <Img className = 'inner_project_text' fluid = {this.props.text_img}></Img>
      </div>
      </div>
      </Link>
    )
  }
  else{
    return(
      <a href= {this.props.path}>
      <div className = 'preview'>
      <div className = 'project'>
      <Img className = 'inner_project' fluid = {this.props.background_img}> </Img>
      <Img className = 'inner_project_text' fluid = {this.props.text_img}></Img>
      </div>
      </div>
      </a>
    )
  }


}
}

export default ProjectPreview

import ReactVivus from 'react-vivus';
import React from "react"

class MapBackground extends React.Component {
  constructor(props) {
  super(props);
  this.state = { width: 0, loaded:false};
  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
}

componentDidMount() {
  this.updateWindowDimensions();
}

updateWindowDimensions() {
  this.setState({ width: window.innerWidth, loaded:true});
}

render() {
  if (this.state.width < 600 && this.state.loaded){
  return (

    <div style ={{position: 'relative'}}>
    <ReactVivus id="foo" option={{file: "https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/mobile_hero.svg?alt=media", type: 'sync',onReady: console.log,duration: 200,start: 'autostart'}}
    css={{top:'0',  left:'0', width:'160%', position:'absolute', zIndex:'-2', '@media (max-width: 400px)': {width:`180%`}}}/>
    </div>
)
}
else if (this.state.width >= 600 && this.state.loaded){
  return (
    <div style ={{position: 'relative'}}>
    <ReactVivus id="foo" option={{file: "https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/desktop_hero.svg?alt=media", type: 'sync',onReady: console.log,duration: 200,start: 'autostart'}}
    css={{top:'0', left:'0',  width:'130%', position:'absolute', zIndex:'-2', '@media (max-width: 1250px)': {width:`200%`, left:'-200'},'@media (max-width: 600px)': {width:`300%`, left:'-400'}}}/>
    </div>
)
}
else {
  return (<div/>)
}
}
}

export default MapBackground;
